@tailwind base;
@tailwind components;
@tailwind utilities;

.translate-z-0 {
  transform: translateZ(0px);
}

.line-color {
  overflow: hidden;
}

@keyframes animate {
  0% {
    top: 100%;
    left: -130%;
  }
  100% {
    left: 130%;
    top: -100%;
  }
}

.line-color:hover::after {
  content: "";
  position: absolute;
  width: 400px;
  height: 100px;
  top: 100%;
  left: -130%;
  transform: rotate(45deg);
  background: linear-gradient(
    0deg,
    transparent,
    transparent,
    #25a4e962,
    #25a4e962,
    #25a4e962
  );
  z-index: 1;
  transform-origin: bottom bottom;
  animation: animate 800ms linear;
  animation-fill-mode: forwards;
}

@keyframes animateicon {
  0% {
    /* box-shadow: 0 5px 15px 0px transparent; */
    color: white;
    /* font-size: large;
    width: 240px; */
    /* height: 48px;  */
    transform: translatey(0px);
  }
  50% {
    color: white;
    /* width: 244px; */
    /* height: 49px; */
    /* box-shadow: 0 25px 15px 0px transparent; */
    transform: translatey(-2px);
  }
  100% {
    /* box-shadow: 0 5px 15px 0px transparent; */
    color: #bfc7d2;
    /* width: 240px; */
    /* height: 48px; */
    transform: translatey(0px);
  }
}

.icon {
  /* width: 66px;
  height: 32px; */

  /* animation: animateicon 1200ms linear; */
  animation: animateicon 2s ease-in-out infinite;
}

.trans {
  place-self: center stretch;
  width: auto;
  height: 60px;

  transition: opacity 0.8s ease, color 0.5s ease, transform 0.5s ease;
}

.gridd {
  margin-top: 40px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: 1fr;
  place-content: center;
  place-items: center;
  gap: 40px;
}

.not-found-body {
  @apply flex flex-col items-center justify-center h-screen bg-transparent;
}
